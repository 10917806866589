import { h, Component } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import logo from '../../assets/logo/logo5.svg';
import './landing.css';
import Landfoot from './landfoot';
import LandNav from './landnav';
const Payments = () => {
    const [colorChange, setColorchange] = useState(true);
    const [openNav, setOpenNav] = useState(false);
    const [tableItems, setTableItems] = useState([]);

    useEffect(() => {
        firebase.database().ref('mpesa/demo').limitToLast(10).on('value', snapshot => {
            let array = [];

            snapshot.forEach(el => {
                let snap = el.val();
                let str = snap.contact;
                let contact = str.replace(str.substring(4,8), "*****");
                let date = new Date(snap.time).toLocaleDateString("en-US");
                let time = new Date(snap.time).toLocaleTimeString("en-US");

                array.push({ ...snap, date:date, time:time, key: el.key, contact:contact});
            });
            setTableItems(array.reverse());
        });


    }, []);

    return (
        <div>
            <LandNav/>
            <section id="about" class="payments" style="margin-bottom: 200px">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>vipisms</h2>
                        <p>Payment Demo</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Contact</th>
                                        <th scope="col">Amount</th>
                                        <th scope='col'>Account</th>
                                        <th scope='col'>Date</th>
                                        <th scope='col'>Time</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {tableItems.map((item) => (
                                        <tr>
                                            <th scope="row">{item.fname}</th>
                                            <th scope="row">{item.contact}</th>
                                            <th scope="row">{item.amount}</th>
                                            <th scope="row">{item.account}</th>
                                            <th scope="row">{item.date}</th>
                                            <th scope="row">{item.time}</th>

                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <p>showing last 10 transactions</p>
                                </tfoot>
                            </table>
                            {/* <img src={img} class="img-fluid" alt="" /> */}
                        </div>
                        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                            <h4></h4>
                            <p>
                                Welcome to our payment demo! We're excited to show you how our payment solutions can help streamline your
                                payment processes and improve your customer experience.
                            </p>
                            <h5>Pay 1 ksh to paybill 300616 with account number 
                                Demo to see your contact in the window</h5>
                            <p>
                            This demonstration serves to showcase our proficiency in the acquisition of 
                            real-time data to meet your specific requirements. Our platform empowers 
                            you to generate Excel reports pertaining to payments made during any designated 
                            time frame. Moreover, you are also able to leverage personalized messaging functionality 
                            to communicate tailored offers to your customers in response to their payment activity on your account.
                            </p>
                            {/* <p>
                                During this demo, we'll walk you through the features and functionality of our payment platform,
                                and help you get started with our payment services.
                                So let's get started! We can't wait to show you how our payment solutions can
                                help take your business to the next level.
                            </p>
                            <p>By the end of this demo, you'll have a clear understanding of how our payment service can benefit your business,
                                and be ready to start accepting payments from your customers. So let's get started and
                                explore the world of hassle-free payments!
                            </p> */}
                        </div>
                    </div>

                </div>
            </section>
            <Landfoot/>
        </div>
    );
};

export default Payments;