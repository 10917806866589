import { h, Component } from 'preact';
import { useState } from 'preact/hooks';
import logo from '../../assets/logo/logo5.svg';
import './landing.css';
import LandNav from './landnav';

const Pricing = () => {
    const [colorChange, setColorchange] = useState(true);
    const [openNav, setOpenNav] = useState(false);

    return (
        <div>
            <LandNav/>
            <section id="services" class="pricing">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>Bulksms</h2>
                        <p>pricing list</p>
                    </div>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">SMS Units Bundling</th>
                                <th scope="col">Cost per Unit (KSH)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1 - 50,000</th>
                                <td>0.70</td>
                            </tr>
                            <tr>
                                <th scope="row">50,001 - 60,000</th>
                                <td>0.60</td>
                            </tr>
                            <tr>
                                <th scope="row">60,001 - 100,000</th>
                                <td>0.50</td>
                            </tr>
                            <tr>
                                <th scope="row">100,001 - 500,000</th>
                                <td>0.40</td>
                            </tr>
                            <tr>
                                <th scope="row">500,001 - 1,000,000</th>
                                <td>0.30</td>
                            </tr>
                            <tr>
                                <th scope="row">1,000,000+</th>
                                <td>0.20</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section id="services">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>Sender ID</h2>
                        <p>pricing</p>
                    </div>
                    <p>Get a sender ID with us for 7,500</p>
                </div>
            </section>

            <section id="cta" class="cta">
                <div class="container" data-aos="zoom-in">

                    <div class="text-center">
                        <p>Can't find what you are looking for?
                            {/* Sign up for our services today and start engaging with
                            your customers in new and powerful ways */}
                        </p>
                        <a class="cta-btn" href="/#contact">Talk To Us</a>
                    </div>

                </div>
            </section>



        </div>
    );
};

export default Pricing;