import { h, Component } from 'preact';
import logo from '../../assets/logo/logo5.svg';
import { useState, useEffect } from 'preact/hooks';

const LandNav = ({page}) => {
    
    const [colorChange, setColorchange] = useState(false);
    const [openNav, setOpenNav] = useState(false);

    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);
    
    return (
        <div>
            <header id="header" class={(colorChange || page != 'home') ? 'fixed-top header-scrolled' : 'fixed-top'}>
                <div class="container d-flex align-items-center justify-content-lg-between">

                    <h1 class="logo me-auto me-lg-0"><a href="index.html"><img src={logo} /><span></span></a></h1>
                    <nav id="navbar" class={openNav ? "navbar order-last order-lg-0 navbar-dark navbar-mobile":"navbar order-last order-lg-0 navbar-dark"}>
                        <ul onClick={()=>setOpenNav(false)}>
                            <li><a class="nav-link scrollto active" href={page === 'home' ? '#hero': '/'}>Home</a></li>
                            <li><a class="nav-link scrollto" href="#about">About</a></li>
                            <li><a class="nav-link scrollto" href="#services">Services</a></li>
                            <li class="dropdown"><a href="#"><span>Products</span> <i class="bi bi-chevron-down"></i></a>
                                <ul class="dropdown-active">
                                    <li><a href="#smsBroadcast">SMS Broadcast</a></li>
                                    <li><a href="#ussd">USSD</a></li>
                                    {/* <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                                        <ul>
                                            <li><a href="#">Deep Drop Down 1</a></li>
                                            <li><a href="#">Deep Drop Down 2</a></li>
                                            <li><a href="#">Deep Drop Down 3</a></li>
                                            <li><a href="#">Deep Drop Down 4</a></li>
                                            <li><a href="#">Deep Drop Down 5</a></li>
                                        </ul>
                                    </li> */}
                                    <li><a href="#shortcode">Short Codes</a></li>
                                    <li><a href="#mobileSurvey">Mobile Surveys</a></li>
                                    <li><a href="#payments">Payments</a></li>
                                </ul>
                            </li>
                            <li><a class="nav-link scrollto " href="/pricing">Pricing</a></li>
                            {/* <li><a class="nav-link scrollto" href="#team">Developers</a></li> */}
                            <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
                        </ul>
                        <i class={openNav ? "bi bi-x mobile-nav-toggle":"bi bi-list mobile-nav-toggle"} onClick={()=>setOpenNav(!openNav)}></i>
                    </nav>

                    <a href="/login" class="get-started-btn scrollto">Join Us</a>

                </div>
            </header>
        </div>
    );
};

export default LandNav;