import { h, Component } from 'preact';

const Landfoot = () => {
    const subToNewsletter = () => {
        if(mail == ''){
            alert('Please fill in email field');
            return;
        }
        else{
            var data = JSON.stringify({
                "email": mail
            });

            var config = {
                method: 'post',
                url: 'https://vipisms.web.app/extras/mailing',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    alert('You have been added to our mailing list');
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    

    return (
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="footer-info">
                                <h3>Vipisms<span>.</span></h3>
                                <p>
                                    Nairobi, <br />
                                    Trio Complex behind Garden City Mall<br /><br />
                                    <strong>Phone:</strong> +254712570000<br />
                                    <strong>Email:</strong> info@vipisms.co.ke<br />
                                </p>
                                <div class="social-links mt-3">
                                    <a href="https://www.instagram.com/vipisms/" class="instagram"><i class="bi bi-instagram"></i></a>
                                    <a href="https://api.whatsapp.com/send?phone=254712570000&text=i+am+interested+in+your+services" class="google-plus"><i class="bi bi-whatsapp"></i></a>
                                    <a href="tel:254712570000" class="linkedin"><i class="bi bi-phone"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#services">Services</a></li>

                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">SMS Broadcast</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">USSD</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Short Codes</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Mobile Surveys</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Payments</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Our Newsletter</h4>
                            <p>Subscribe to our newsletter to keep up to date with the latest offers</p>
                            <form action="javascript:void(0);">
                                <input type="email" name="email" onChange={e => { setMail(e.target.value) }} />
                                <input type="submit" value="Subscribe" onClick={subToNewsletter} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>Vipisms</span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                    Vipisms is a product of <a href="https://ifix.co.ke/">Ifix (k) LTD</a> and is authorized
                    and regulated by the Communications Authority of Kenya.
                </div>
            </div>
        </footer>
    );
};

export default Landfoot;