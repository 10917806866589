import { h, Component } from 'preact';
import { useState, useEffect } from 'preact/hooks'
import Loading from './routes/Loading';
import Bulksms from './routes/bulksms';
import Landing from './routes/landing';
import Fresh from './routes/fresh';
import Router from 'preact-router';
import Login from './routes/auth/oldLogin';
import './app.css';
import Docs from './routes/docs';
import Pricing from './routes/landing/pricing';
import Payments from './routes/landing/payments';
export default function App() {
	const [loggedIn, setLoggedIn] = useState(true);
	const [loading, setLoading] = useState(false);
	const [account, setAccount] = useState('none');
	const [uid, setUid] = useState('none');


	useEffect(() => {
		setLoading(true);
		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				setLoggedIn(true);
				setUid(user.uid);
				firebase.database().ref("account/" + user.uid).once('value').then(snapshot => {
					if (snapshot.val()) {
						setAccount(snapshot.val());
						console.log(snapshot.val());
					}
					else {
						setAccount('none');
						console.log('none');
					}
					setLoading(false);
				});
			} else {
				setLoggedIn(false);
				setLoading(false);
			}
		});
	}, [loggedIn]);

	return (
		<div>
			{loading && <Loading />}
			<div></div>
			{loggedIn ?			
				<div>
					{account === 'none' && 
					<div>
						<Fresh uid={uid} />
					</div>}
					<div></div>
					{account != 'none' && <Bulksms uid={uid} account={account}/>}
					<div></div>
				</div>
				:
				<div>
					<Router>
						<Landing path='/'/>
						<Pricing path='/pricing'/>
						<Login path='/login' />
						<Docs path='/developers'/>
						<Payments path="/payments"/>
					</Router>
				</div>
				
			}
		</div>
	);
}
