import { h, Component } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import './login.css';
import img from '../../../assets/images/login2.svg';

const Login = () => {
    const [account, setAccount] = useState('signin');
    const [lemail, setLemail] = useState('');
    const [lpass, setLpass] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [logBtn, setLogBtn] = useState('Login');
    const [signBtn, setSignBtn] = useState('Create Account');
    const [rEmail, setREmail] = useState('');
    const [lreset, setLReset] = useState('Reset');


    const login = () => {
        setLogBtn('logging in...');
        if (lemail.trim().length < 1) {
            alert('please enter a valid email');
            setLogBtn('try again');

        }
        else if (lpass.trim().length < 1) {
            alert('please enter a valid password');
            setLogBtn('try again');

        }
        else {
            firebase.auth().signInWithEmailAndPassword(lemail, lpass)
                .then((userCredential) => {
                    // Signed in
                    var user = userCredential.user;
                    console.log(user);
                    setLogBtn('redirecting...');

                })
                .catch((error) => {
                    //var errorCode = error.code;
                    var errorMessage = error.message;
                    alert(errorMessage);
                    setLogBtn('try again');
                });
        }
    }


    const signup = async () => {
        setSignBtn('Creating Account...');
        if (name.trim().length < 1) {
            alert('please enter a valid name');
            setSignBtn('try again');
        }
        else if (contact.trim().length != 9) {
            alert('please enter a valid contact');
            setSignBtn('try again');

        }
        else if (email.trim().length < 3) {
            alert('please enter a valid email');
            setSignBtn('try again');

        }
        else if (password.trim().length < 8) {
            alert('password too short');
            setSignBtn('try again');

        }
        else if (confirmPassword.trim().length < 8) {
            alert('password too short');
            setSignBtn('try again');

        }
        else if (password !== confirmPassword) {
            alert('passwords do not match');
            setSignBtn('try again');

        }
        else {
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    // Signed in
                    let user = userCredential.user;
                    let uid = userCredential.user.uid;
                    console.log(user);

                    firebase.database().ref('users/' + uid + '/').set({
                        name,
                        uid,
                        contact: '254' + contact,
                        email,
                        time: Date.now()
                    });
                })
                .catch((error) => {
                    //var errorCode = error.code;
                    var errorMessage = error.message;
                    alert(errorMessage);
                    setSignBtn('try again');

                });
        }
    }
    const resetPass = () => {
        setLReset('Resetting account');
        if (rEmail.trim().length < 3) {
            alert('please enter a valid email');
        }
        else {
            firebase.auth().sendPasswordResetEmail(rEmail)
                .then(() => {
                    alert('Password reset email has been sent to your email.');
                    setAccount('signin');
                    // Password reset email sent!
                    // ..
                })
                .catch((error) => {
                    console.log(error);
                    alert(error);
                    setLReset('try again');
                });
        }
    }


    return (
        <div className="login">
            <div className="login-container">
                <div className="login-left">
                    <img src={img} alt="login-img" className="login-img" />
                    <h2 className="login-lheader">You are one step away from accessing our services.</h2>
                </div>
                <div className="login-right">
                    {account === 'signin' &&
                        <>
                            <h1>Sign In</h1>

                            <input className="login-input login-email" onChange={e => setLemail(e.target.value)} placeholder="email" type="email" />
                            <input className="login-input login-email" onChange={e => setLpass(e.target.value)} placeholder="password" type="password" />

                            <div className="login-btn" onClick={login}>{logBtn}</div>

                            <p className="login-link">Don't have an account? <span className="login-link-btn" onClick={() => setAccount('signup')}>Sign Up</span></p>
                            <p className="login-link-btn login-link-btn-forgot" onClick={() => setAccount('forgot')}>Forgot Password?</p>
                        </>
                    }
                    {account === 'signup' &&
                        <>
                            <h1>Create an Account</h1>
                            <div className="login-row">
                                <input className="login-input login-fname" onChange={e => setName(e.target.value)} placeholder="Name" type="name" />
                                <div className='login-contact'>
                                    <span className="login-pre-contact">+254</span>
                                    <input className="login-input login-icontact" onChange={e => setContact(e.target.value)} placeholder="Contact" type="contact" />
                                </div>
                            </div>
                            <input className="login-input login-email" onChange={e => setEmail(e.target.value)} placeholder="email" type="email" />
                            <div className="login-row">
                                <input className="login-input login-pass" onChange={e => setPassword(e.target.value)} placeholder="password" type="password" />
                                <input className="login-input login-cpass" onChange={e => setConfirmPassword(e.target.value)} placeholder="confirm password" type="password" />
                            </div>
                            <div className="login-btn" onClick={signup}>{signBtn}</div>

                            <p className="login-link">Already have an account? <span className="login-link-btn" onClick={() => setAccount('signin')}>Sign In</span></p>
                        </>
                    }

                    {account === 'forgot' &&
                        <>
                            <h1>Forgot your password</h1>

                            <input className="login-input login-email" onChange={e => setREmail(e.target.value)} placeholder="email" type="email" />

                            <div className="login-btn" onClick={resetPass}>{lreset}</div>

                            <p className="login-link">Go back? <span className="login-link-btn" onClick={() => setAccount('signin')}>Log In</span></p>
                        </>
                    }
                </div>

            </div>


        </div>
    );
};

export default Login;